<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <div class="separate">
          <el-drawer title="选择部门" :visible.sync="drawer_show" direction="ltr">
            <BepartmentTree @on_controlid="on_controlid" />
          </el-drawer>
          <div class="tablemessage">
            <p class="describe">筛选</p>
            <div class="segmentation"></div>
            <div class="from_submit">
              <el-form :model="formLabelAlign" class="from">
                <el-form-item label="部门：" label-width="60px" style="width: 310px;">
                  <el-input disabled @click.native="drawer_show = true" placeholder="请点击选择部门" size="mini" class="form-inp"
                    v-model="department_name"></el-input>
                </el-form-item>
                <el-form-item label="隐患名称：" label-width="90px" style="width: 340px;">
                  <el-input placeholder="请输入隐患名称" size="mini" class="form-inp" v-model="formLabelAlign.name"></el-input>
                </el-form-item>

                <el-form-item label="风险等级：" label-width="90px" style="width: 450px;">
                  <el-select v-model="formLabelAlign.level_color" placeholder="请选择">
                    <el-option v-for="item in color_list" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="分类：" label-width="60px" style="width: 310px;">
                  <el-cascader class="form-inp" v-model="formLabelAlign.cate_id" :options="cate" :props="{
                    checkStrictly: true,
                    label: 'name',
                    children: 'items',
                    value: 'id',
                  }" @change="formLabelAlignChange"></el-cascader>
                </el-form-item>

                <el-form-item label="隐患状态：" label-width="90px">
                  <el-select v-model="formLabelAlign.type" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <div class="search_button">
                <div>
                  <el-button @click="way_tableData(true)" type="primary" icon="el-icon-search">查询</el-button>
                  <el-button @click="on_reset" icon="el-icon-refresh-left">重置</el-button>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个隐患</span>
            <span class="span2">已选择 {{ more_List.length }} 个隐患</span>
            <i @click="way_tableData" class="span_i el-icon-refresh">数据更新</i>
          </p>
          <div>
            <el-button type="primary" @click="on_pop_addplus()" icon="el-icon-plus">上报</el-button>
            <!-- <el-button @click="on_pop_flow" icon="el-icon-s-custom"
                    >审批</el-button
                  > -->
            <el-button @click="on_poprw_flow" icon="el-icon-tickets">审批任务</el-button>
            <!-- <el-button icon="el-icon-document-remove" plain>评估</el-button> -->
            <el-button @click="on_deleteList" icon="el-icon-close">删除</el-button>
            <!-- <el-button @click="on_export">导出</el-button> -->
          </div>
        </div>
        <div>
          <el-table highlight-current-row @current-change="way_singleselect" @selection-change="way_moreselect"
            :data="table_List" :header-cell-style="{ background: '#f8f8f9' }" style="flex: 1">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="name" label="名称">
              <template slot-scope="scope">
                <el-link class="list_sty" type="primary" @click="on_particulars(scope.row.id)">{{ scope.row.name
                }}</el-link>
              </template>
            </el-table-column>
            <el-table-column prop="cate_name" label="分类"></el-table-column>

            <el-table-column prop="department" label="责任部门"></el-table-column>

            <el-table-column prop="address" label="风险等级">
              <template slot-scope="scope">
                <div class="sign">
                  <!-- <div
                          class="sign-1"
                          :style="{ background: scope.row.back_color }"
                        >
                          {{ scope.row.level_type }}
                        </div> -->
                  <!-- <div
                          class="sign-2"
                          :style="{
                            border: `1px  solid ${scope.row.back_color}`,
                            color: scope.row.back_color,
                          }"
                        >
                          {{ scope.row.level_color }}
                        </div> -->

                  <div class="sign-2" :style="{
                      color: scope.row.back_color,
                    }">
                    {{ scope.row.level_color }}
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="处理状态" prop="status_tips"></el-table-column>

            <el-table-column prop="nickname" label="上报人"></el-table-column>

            <el-table-column prop="add_time" label="上报时间"></el-table-column>

            <!-- <el-table-column prop="address" label="任务完成">
                    <template slot-scope="scope">
                      <div class="schedule">{{ scope.row.finish_percent }}</div>
                    </template>
                  </el-table-column> -->

            <el-table-column prop="last_score" label="扣分"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <!-- <el-link
                        class="list_sty"
                        type="primary"
                        @click="on_pop_addplus(scope.row.id)"
                        >修改</el-link
                      > -->
                <div v-if="scope.row.is_real == 1">
                  <el-link v-if="scope.row.status == 0 || scope.row.status == 4" @click="on_assess(scope.row.id, 1)"
                    class="list_sty" type="primary">审批</el-link>
                  <el-link v-if="scope.row.status == 1" class="list_sty" disabled>审批中</el-link>
                  <div v-if="scope.row.department_id">

                    <el-link v-if="scope.row.status == 2 || scope.row.status == 4" class="list_sty" type="primary"
                      @click="on_waypop(2, scope.row.id)">验收</el-link>
                    <el-tag v-if="scope.row.status == 3">处理完成</el-tag>
                  </div>
                </div>
                <div v-else>
                  <el-tag v-if="scope.row.is_real == 2">非隐患</el-tag>
                  <el-tag v-if="scope.row.is_real == 3">重复上报</el-tag>

                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination @current-change="way_page" :page-size="page_size" layout="prev, pager, next, jumper"
            :total="total" :current-page="page">
          </el-pagination>
        </div>
      </div>

      <!-- 整改弹窗 -->
      <el-dialog show-close title="整改" :visible.sync="newly_show" center>
        <el-form ref="formName" label-width="180px" :model="newly_From">
          <el-form-item label="整改完成：">
            <el-switch v-model="newly_From.form_is_normal" active-text="是" inactive-text="否" active-value="1"
              inactive-value="2">
            </el-switch>
          </el-form-item>
          <el-form-item label="详情：">
            <el-input type="textarea" placeholder="请输入详情" size="mini" class="form-textarea"
              v-model="newly_From.form_detail"></el-input>
          </el-form-item>
          <el-form-item label="现场图片：">
            <uploadingimg v-model="newly_From.img_List" />
          </el-form-item>
          <el-form-item label="现场视频：">
            <uploadingVideo v-model="newly_From.video_List" />
          </el-form-item>
        </el-form>
        <div style="text-align: center; margin: 20px 0">
          <el-button @click="on_newlypreserve('formName')" type="primary">确定</el-button>
          <el-button @click="newly_show = false">返回</el-button>
        </div>
      </el-dialog>

      <!-- 验收弹窗 -->
      <el-dialog title="验收" show-close :visible.sync="check_show" center width="800px" @closed="closed_check">
        <el-form ref="check_formName" label-width="100px" :model="check_From">
          <el-form-item label="是否通过：">
            <el-switch v-model="check_From.is_pass" active-text="是" inactive-text="否" active-value="1" inactive-value="2">
            </el-switch>
          </el-form-item>
          <el-form-item label="验收报告：">
            <el-input type="textarea" placeholder="请输入验收报告" size="mini" class="form-textarea"
              v-model="check_From.form_detail"></el-input>
          </el-form-item>

          <div style="text-align: center; margin: 20px 0">
            <el-button @click="on_checkserve('check_formName')" type="primary">确定</el-button>
            <el-button @click="check_show = false">返回</el-button>
          </div>
        </el-form>
      </el-dialog>

      <!-- 新建修改弹窗 -->
      <el-dialog :title="title" show-close :visible.sync="dialog_show" center width="800px" :close-on-click-modal="false">
        <Riskmanagemredact v-if="dialog_show" ref="Riskmanagemredact" :refbool.sync="dialog_show"
          @way_tableData="way_tableData" />
      </el-dialog>
    </div>
    <el-dialog title="发起审批" show-close :visible.sync="auditpop_show" center>
      <auditpop v-if="auditpop_show" ref="auditpop" :refbool.sync="auditpop_show" flowcode_type="flow_hdanger"
        @way_tableData="way_tableData" />
    </el-dialog>

    <el-dialog title="任务" show-close :visible.sync="renwupop_show" center>
      <renwupop v-if="renwupop_show" ref="renwupop" :refbool.sync="renwupop_show" task_code="hdanger_recorder"
        @way_tableData="way_tableData" />
    </el-dialog>
    <!-- 自定义整改 -->

    <el-dialog show-close title="新增管控" :visible.sync="Custom_show" center>
      <CustomFromanalysis v-if="Custom_show" ref="CustomFromanalysis" :frmcode="frmcode" :formvalue="formvalue"
        :refbool.sync="Custom_show" @way_tableData="way_tableData" />
    </el-dialog>
    <!-- 审批弹窗 -->
    <el-dialog title="审批" :visible.sync="assess_pop" show-close center>
      <el-form label-width="150px" ref="formName" :model="assess_Data">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="是否隐患：" prop="is_real" :rules="[{ required: true, message: '请选择是否隐患' }]">
              <el-select v-model="assess_Data.is_real" placeholder="请选择是否隐患">
                <el-option v-for="item in enable_list" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="整改期限：" prop="end_time" :rules="[{ required: true, message: '请输入整改期限' }]">
              <el-input onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="整改期限"
                v-model.number="assess_Data.end_time" class="form-inp">
                <template slot="append">天</template>
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="责任部门：" prop="department_id" :rules="[{ required: true, message: '请选择部门' }]">
              <Bepartment ref="assess_Bepartment" class="form-inp" v-model="assess_Data.department_id" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="任务表单：" prop="scheme_id" :rules="[{ required: true, message: '请选择任务表单' }]">
              <el-select class="form-inp" v-model="assess_Data.scheme_id" placeholder="任务表单：">
                <el-option v-for="item in scheme_list" :key="item.value" :label="item.task_name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="隐患分类：" prop="cate_id" :rules="[{ required: true, message: '请选择分类' }]">
              <el-cascader class="form-inp" :value="assess_Data.cate_id" v-model="assess_Data.cate_id" :options="cate"
                :props="{
                  checkStrictly: true,
                  label: 'name',
                  children: 'items',
                  value: 'id',
                }" @change="assessChange"></el-cascader>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item
              label="评估等级："
              prop="level_color"
              :rules="[{ required: true, message: '请选择评估等级' }]"
            >
              <el-select
                v-model="assess_Data.level_color"
                placeholder="请选择评估等级"
              >
                <el-option
                  v-for="item in level_color_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="考核分数">
              <el-input
                type="number"
                placeholder="考核分数"
                size="mini"
                class="form-inp"
                v-model="assess_Data.score"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item
              label="任务执行对象："
              prop="checkedCities"
              :rules="[{ required: true, message: '请选择任务执行对象' }]"
            >
              <el-checkbox-group v-model="assess_Data.checkedCities">
                <el-checkbox
                  v-for="city in office_position"
                  :label="city"
                  :key="city.id"
                  >{{ city.name }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">审批</el-button>
        <el-button @click="assess_pop = false">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import table_minin from "../../assets/minin/table_minin";
import BepartmentTree from "../../components/Bepartment_tree";
import auditpop from "../../components/audit_pop";
import renwupop from "../../components/renwu_pop";
import Riskmanagemredact from "../../components/view_redact/Risk_managemredact";
import Bepartment from "../../components/Bepartment";
import uploadingimg from "../../components/uploading/uploading_img.vue";
import uploadingVideo from "../../components/uploading/uploading_Video.vue";
import CustomFromanalysis from "../../components/custom_From/custom_Fromanalysis.vue";

import {
  hdangerindex,
  hdangerstatusupdate,
  hdangerdelete,
  hdangerestimateInfo,
  hdangerestimateSave,
  ay_recorderSave,
  hdangercheckpass,
  ay_addrecorder,
} from "../../assets/request/api";
export default {
  name: "risk_source",
  mixins: [table_minin],
  components: {
    BepartmentTree,
    Riskmanagemredact,
    auditpop,
    renwupop,
    Bepartment,
    uploadingimg,
    uploadingVideo,
    CustomFromanalysis,
  },
  data() {
    return {
      drawer_show: false,
      department_name: "",

      title: "",
      dialog_show: false,
      is_extend: true,
      color_list: [],
      cate: [],
      formLabelAlign: {
        name: "",
        status: "",
        level_color: "",
        type: "",
        cate_id: "",
      },
      //审批操作
      assess_pop: false,
      assess_Data: {
        id: "",
        level_color: "",
        cate_id: "",
        score: 0,
        is_real: null,
        department_id: "",
        scheme_id: null,
        checkedCities: [],
        end_time: 7,
      },
      enable_list: [],
      level_color_list: [],
      level_list: [],
      scheme_list: [],
      office_position: [],
      //整改
      newly_show: false,
      newly_From: {
        source_id: "",
        form_detail: "",
        video_List: [],
        img_List: [],
        form_is_normal: "1",
      },
      //自定义表单管控
      Custom_show: false,
      frmcode: "",
      formvalue: "",
      //验收
      check_show: false,
      check_From: {
        source_id: "",
        is_pass: "1",
        form_detail: "",
      },
    };
  },
  created() {
    this.url = hdangerindex;
    this.is_enableUrl = hdangerstatusupdate;
    this.delete_Url = hdangerdelete;
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "status_list";

    this.way_tableData();
  },
  methods: {
    way_extend(data) {
      console.log(data);
      this.color_list = data.color_list;
      this.cate = data.catesList;
    },
    //点击任务
    on_poprw_flow() {
      let { more_List } = this;
      if (more_List.length == 0) {
        this.$message({
          message: "请选择后在选择任务",
          type: "warning",
        });
        return;
      }
      let id = more_List.map((item) => item.id).join(",");
      this.on_assess(id);
    },
    on_pop_addplus(id) {
      this.dialog_show = true;
      this.title = id ? "修改" : "新增";
      this.$nextTick(function () {
        this.$refs.Riskmanagemredact.way_show(id);
      });
    },

    formLabelAlignChange(event) {
      let events = event[event.length - 1];
      this.formLabelAlign.cate_id = events;
    },
    assessChange(event) {
      let events = event[event.length - 1];
      this.assess_Data.cate_id = events;
    },
    on_assess(id, type) {
      this.assess_Data.id = id;
      hdangerestimateInfo({ id }).then((res) => {
        let {
          level_color_list,
          info,
          level_list,
          enable_list,
          scheme_list,
          office_position,
        } = res.data;
        let { score, level_color, status, department_id, cate_id, end_time, is_real } =
          info;

        this.level_color_list = level_color_list;
        this.level_list = level_list;
        this.enable_list = enable_list;
        this.scheme_list = scheme_list;
        this.office_position = office_position;
        this.assess_pop = true;
        this.assess_Data.scheme_id = scheme_list[0].id;
        if (type == 1) {
          this.assess_Data.score = score;
          this.assess_Data.status = status;
          if (end_time) {
            this.assess_Data.end_time = end_time;
          }
          this.assess_Data.level_color = level_color || "";
          this.assess_Data.cate_id = cate_id || "";
          this.assess_Data.is_real = is_real || null;
          if (department_id) {
            this.assess_Data.department_id = department_id;
            this.$nextTick(function () {
              this.$refs.assess_Bepartment.bepartment_List = department_id;
            });
          }
        }
      });
    },

    on_waypop(type, id) {
      if (type == 1) {
        let that = this;
        ay_addrecorder("hdanger", { id }).then((res) => {
          console.log(res);
          let { data } = res;
          if (data.mode == 0) {
            this.newly_From.source_id = id;
            this.newly_show = true;
          } else if (data.mode == 1) {
            let { frmcode, formvalue, info } = data;
            that.frmcode = frmcode;
            that.formvalue = formvalue;
            this.Custom_show = true;
            this.$nextTick(function () {
              that.$refs.CustomFromanalysis.way_Datadispose(info.id);
            });
          }
        });
      } else if (type == 2) {
        this.check_From.source_id = id;
        this.check_show = true;
      } else if (type == 0) {
        this.renwupop_show = true;
        this.$nextTick(function () {
          this.$refs.renwupop.way_show(id);
        });
      }
    },

    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { assess_Data } = this;
          let From_Data = JSON.parse(JSON.stringify(assess_Data));
          From_Data.office_position = assess_Data.checkedCities
            .map((item) => item.id)
            .join(",");
          delete From_Data.checkedCities;
          console.log(From_Data);
          From_Data.end_time = this.$tools.getNextDate(From_Data.end_time);
          hdangerestimateSave(From_Data).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.way_tableData();
              this.assess_pop = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    //验收保存
    on_checkserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { check_From } = this;
          hdangercheckpass(check_From).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.way_tableData();
              setTimeout(function () {
                that.check_show = false;
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //验收清除
    closed_check() {
      this.check_From.source_id = "";
      this.check_From.is_pass = "1";
      this.check_From.form_detail = "";
    },

    //整改保存
    on_newlypreserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let newly_From = JSON.parse(JSON.stringify(this.newly_From));
          newly_From.vedio = newly_From.video_List.join(",");
          newly_From.images = newly_From.img_List.join(",");
          delete newly_From.video_List;
          delete newly_From.img_List;
          let url_type = "hdanger";
          ay_recorderSave(url_type, newly_From).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              setTimeout(function () {
                that.newly_show = false;
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    on_particulars(id) {
      this.$router.push({
        path: "/security/Risk_manparticulars",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}

.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}

.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.form-inp {
  width: 200px;
}

.paging {
  text-align: center;
  padding: 10px;
}

.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  button {
    margin-right: 10px;
  }

  p {
    display: flex;
    align-items: center;
  }
}

.span1 {
  font-size: 14px;
  color: #000000;
}

.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}

.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}

.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}

.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.list_sty {
  margin-left: 10px;
}

.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}

.sign {
  display: flex;

  div {
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 9px;
    color: #ffffff;
  }
}

.sign-1 {
  border-radius: 5px 0 0 5px;
}

.sign-2 {
  border-radius: 0 5px 5px 0;
}

.el-col-12 {
  height: 51px;
}
</style>
