<template>
  <div class="tree_sty">
    <el-tree
      :data="bepartment_Data"
      :props="{
        children: 'items',
        label: 'name',
      }"
      node-key="id"
      @node-click="nodeclick"
    ></el-tree>
  </div>
</template>

<script>
import { departmentindex } from "../assets/request/api";
export default {
  name: "Bepartment",
  data() {
    return {
      bepartment_Data: [],
    };
  },
  created() {
    this.way_departmentindex();
  },
  methods: {
    //部门信息
    way_departmentindex() {
      departmentindex().then((res) => {
        let { data, enable_list } = res.data;
        this.bepartment_Data = data;
        this.$forceUpdate()
      });
    },
    nodeclick(item) {
      this.$emit("on_controlid", item);
    },
  },
};
</script>

<style lang="less" scoped>
.tree_sty {
  overflow-y: scroll;
  height: calc(100vh - 80px);
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
::-webkit-scrollbar { width: 0 !important }
/* 点击后的当前节点的样式 */
/deep/ .el-tree-node.is-current > .el-tree-node__content {
  color: #ffffff;
  background: rgb(64, 158, 255) !important;
  padding: 0 10px;
}
/* 点击后的当前节点的子节点的背景颜色 */
/deep/ .el-tree > .el-tree-node.is-current {
  // background: #1f2d3d;
}
/* 鼠标悬浮的当前节点  */
/deep/ .el-tree-node__content:hover {
  background: #f8f8f9;
  color: rgb(64, 158, 255);
}
</style>