<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="120px" :model="formLabelAlign">
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="隐患标题："
              prop="name"
              :rules="[{ required: true, message: '请输入隐患标题' }]"
            >
              <el-input
                placeholder="请输入标题"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="隐患地点：">
              <div style="display: flex; align-items: center">
                <el-input
                  placeholder="请输入隐患地点"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.place"
                ></el-input>
                <!-- <el-tag
                  closable
                  style="margin-left: 10px"
                  @close="handleClose()"
                  v-if="formLabelAlign.source_name"
                  >{{ formLabelAlign.source_name }}</el-tag
                >
                <el-button
                  style="margin-left: 10px"
                  @click="on_hidden"
                  type="primary"
                  >选择隐患源</el-button
                > -->
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="责任部门："
              prop="department_id"
              :rules="[{ required: true, message: '请选择责任部门' }]"
            >
              <Bepartment
                ref="Bepartment"
                class="form-inp"
                v-model="formLabelAlign.department_id"
              />
            </el-form-item>
          </el-col>

          <!-- <el-col :span="12" v-if="id">
            <el-form-item
              label="隐患状态："
              prop="status"
              :rules="[{ required: true, message: '请选择隐患状态' }]"
            >
              <el-select
                class="form-inp"
                v-model="formLabelAlign.status"
                placeholder="隐患状态："
              >
                <el-option
                  v-for="item in status_list"
                  :key="item.name"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->

          <!-- <el-col :span="12">
            <el-form-item label="评估标准：">
              <el-select
                v-model="formLabelAlign.level_type"
                placeholder="请选择评估标准"
                class="form-inp"
              >
                <el-option
                  v-for="item in estimate_type"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="分类：" prop="cate_id">
              <el-cascader
                class="form-inp"
                v-model="formLabelAlign.cate_id"
                :options="cate"
                :props="{
                  checkStrictly: true,
                  label: 'name',
                  children: 'items',
                  value: 'id',
                }"
                @change="dataChange"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="风险等级：">
              <el-select
                v-model="formLabelAlign.level_color"
                placeholder="请选择风险等级"
                class="form-inp"
              >
                <el-option
                  v-for="item in color_level"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="考核分数：">
              <el-input
                type="number"
                placeholder="考核分数"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.score"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="隐患描述：" style="width:fit-content">
              <el-input
                type="textarea"
                placeholder="请输入隐患描述"
                size="mini"
                class="form-textarea"
                v-model="formLabelAlign.desc"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="现场图片：">
              <uploadingimg v-model="formLabelAlign.img_List" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="视频或录音：">
              <uploadingVideo v-model="formLabelAlign.video_List" />
            </el-form-item>
          </el-col>

         
        </el-row>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>

    <!-- 选择隐患弹窗 -->
    <el-dialog
      show-close
      append-to-body
      title="选择隐患源"
      :visible.sync="dialog_show"
      center
    >
      <div class="from_submit">
        <el-form label-width="120px" :model="caution_from" class="from">
          <el-form-item label="隐患类型：">
            <el-select
              class="form-inp"
              v-model="formLabelAlign.type"
              placeholder="隐患类型："
              @change="changetype"
            >
              <el-option
                v-for="item in type_list"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="隐患源名称：">
            <el-input
              placeholder="请输入隐患源名称"
              size="mini"
              class="pop-inp"
              v-model="caution_from.name"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="search_button">
          <div>
            <el-button
              @click="way_retu(true)"
              type="primary"
              icon="el-icon-search"
              >查询</el-button
            >
          </div>
        </div>
      </div>
      <el-table
        ref="labeltable"
        highlight-current-row
        height="300px"
        :data="risk_List"
        :header-cell-style="{ background: '#f8f8f9' }"
      >
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="name" label="隐患源名称"> </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-link
              class="list_sty"
              type="primary"
              @click="on_addcaution(scope.row)"
              >选择</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @current-change="on_changecaution"
          :current-page="caution_from.page"
          :page-size="caution_from.page_size"
          :total="risk_total"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  hdangersaveInfo,
  hdangersave,
  hdangergetSourceByType,
} from "../../assets/request/api";
import Bepartment from "../Bepartment.vue";
import uploadingimg from "../../components/uploading/uploading_img.vue";
import uploadingVideo from "../../components/uploading/uploading_Video.vue";
export default {
  name: "Risk_managemredact",
  components: {
    Bepartment,
    uploadingimg,
    uploadingVideo,
  },
  data() {
    return {
      dialog_show: false,
      type_name: "保存",
      //要修改的id
      id: "",
      cate: [],
      enable_list: [],
      status_list: [],
      estimate_type: [],
      type_list: [],
      color_level: [],
      formLabelAlign: {
        name: "",
        place: "",
        department_id: "",
        desc: "",
        type: "",
        status: "",
        source_id: "",
        source_name: "",
        level_color: 4,
        score: 0,
        cate_id: "",

        video_List: [],
        img_List: [],
      },
      teacher_Data: [],
      TSmaster_id: "",
      search_Data: "",
      //风险源列表的数据
      caution_from: {
        name: "",
        page_size: 10,
        page: 1,
      },
      risk_List: [],
      risk_total: 0,
    };
  },
  methods: {
    // 插眼
    on_hidden() {
      this.formLabelAlign.type = this.formLabelAlign.type
        ? this.formLabelAlign.type
        : this.type_list["1"].value;
      this.way_retu();
      this.dialog_show = true;
    },

    changetype() {
      this.formLabelAlign.source_id = "";
      this.formLabelAlign.source_name = "";
      this.way_retu(true);
    },
    way_retu(is) {
      if (is) {
        this.caution_from.page = 1;
      }
      let type = this.formLabelAlign.type;
      hdangergetSourceByType({
        type,
        page: this.caution_from.page,
        page_size: 10,
      }).then((res) => {
        console.log(res);
        this.risk_total = res.data.total;
        this.risk_List = res.data.data;
      });
    },
    //选择隐患源
    on_addcaution(row) {
      this.formLabelAlign.source_id = row.id;
      this.formLabelAlign.source_name = row.name;
      this.dialog_show = false;
    },
    //删除隐患源
    handleClose() {
      this.formLabelAlign.source_id = "";
      this.formLabelAlign.source_name = "";
    },
    on_changecaution(val) {
      this.caution_from.page = val;
      this.way_cautionList();
    },
    dataChange(event) {
      let events = event[event.length - 1];
      console.log(events);
      this.formLabelAlign.cate_id = events;
    },
    way_show(id) {
      let froms = {};
      if (id) froms.id = id;
      hdangersaveInfo(froms).then((res) => {
        let {
          info,
          status_list,
          enable_list,
          type_list,
          estimate_type,
          color_level,
          cate,
        } = res.data;
        this.cate = cate;
        this.status_list = status_list;
        this.enable_list = enable_list;
        this.color_level = color_level;
        this.estimate_type = estimate_type;
        this.type_list = type_list;
        if (id) {
          this.id = id;
          this.type_name = "修改";
          let { formLabelAlign } = this;
          let {
            name,
            status,
            department_id,
            type,
            cate_id,
            // level_type,
            level_color,
            source_id,
            source_name,
            images,
            vedio,
            score,
            desc,
            place,
          } = info;
          this.$refs.Bepartment.bepartment_List = department_id;
          formLabelAlign.department_id = department_id;
          formLabelAlign.id = id;
          formLabelAlign.name = name;
          formLabelAlign.place = place;
          formLabelAlign.type = type;
          formLabelAlign.status = status;
          formLabelAlign.source_id = source_id;
          formLabelAlign.source_name = source_name;
          // formLabelAlign.level_type = level_type;
          formLabelAlign.level_color = level_color;
          formLabelAlign.score = score;
          formLabelAlign.cate_id = cate_id;
          formLabelAlign.desc = desc;
          if (info.vedio) {
            formLabelAlign.video_List = vedio.split(",");
          }
          if (info.images) {
            formLabelAlign.img_List = images.split(",");
          }
        }
      });
    },

    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formLabelAlign = JSON.parse(JSON.stringify(this.formLabelAlign));
          formLabelAlign.vedio = formLabelAlign.video_List.join(",");
          formLabelAlign.images = formLabelAlign.img_List.join(",");
          delete formLabelAlign.video_List;
          delete formLabelAlign.img_List;
          hdangersave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.route_go();
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 200px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}

.form-textarea {
  width:580px;
  margin-bottom: 10px;
}
.el-col-12 {
  height: 50px;
}
</style>